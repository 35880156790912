<template>
  <div class="px-3 mt-5">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="row" @submit.prevent="handleSubmit(submit)">
        <div class="col-sm-12 text-start">
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="name" class="text-muted mb-2">通路名稱</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="text" class="form-control" id="name"
                    v-model="name"
                    placeholder="通路名稱">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="profit" class="text-muted mb-2">分潤比例</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="number" class="form-control" id="profit"
                    step="0.1" 
                    min="0.0"
                    max="100.0"
                    v-model="profit"
                    placeholder="分潤比例 ex. 20.5"
                    >
                    <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="royalty" class="text-muted mb-2">使用費比例</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="text" class="form-control" id="royalty"
                    v-model="royalty"
                    placeholder="使用費比例 ex. 20.5">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="startTime" class="text-muted mb-2">起始日期</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <date-picker v-model="startTime" value-type="format" format="YYYY-MM-DD"></date-picker>
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="endTime" class="text-muted mb-2">結束日期</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <date-picker v-model="endTime" value-type="format" format="YYYY-MM-DD"></date-picker>
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="code" class="text-muted mb-2">行銷代碼</label>
              <ValidationProvider rules='required' v-slot='{ errors , classes }'>
                <div :class="classes">
                  <input type="text" class="form-control" id="name"
                    v-model="code"
                    placeholder="行銷代碼">
                  <span class="text-danger mt-1">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-end">
          <button class="btn btn-main" type="submit"
          >儲存</button>
        </div>
      </form>
    </ValidationObserver>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__User',
  data() {
    return {
      name: '',
      profit: '',
      royalty: '',
      mode: 'edit',
      errorPsd: '',
      id: 0,
      metaTitle: '課程資訊 / 課程 / 分潤設定 - 元學堂',
      startTime: '',
      endTime: '',
      code: ''
    };
  },
  components: {
    DatePicker
  },
  metaInfo: {
    title: "課程資訊 / 課程 / 分潤設定 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '課程資訊 / 課程 / 分潤設定 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  watch: {
    endTime(newVal, oldVal) {
      if(new Date(this.endTime).getTime() < new Date(this.startTime).getTime()) {
        Swal.fire(
          '',
          '結束日期不得小於起始日期',
          'info',
        );
      }
    }
  },
  methods: {
    submit() {
      const vm = this;
      this.updateLoading(true);
      if(new Date(this.endTime).getTime() < new Date(this.startTime).getTime()) {
        this.updateLoading(false);
        Swal.fire(
          '',
          '結束日期不得小於起始日期',
          'info',
        );
        return;
      }
      let data = {
        name: this.name,
        royaltyTaxRate: (this.royalty) / 100,
        diversionTaxRate: (this.profit) / 100,
        startTime: this.startTime,
        endTime: this.endTime,
        code: this.code
      }
      let url = `${this.$API_PATH}/Merchant/SalesChannel`;
      let method = 'post';
      if(this.mode === 'edit') {
        url = `${this.$API_PATH}/Merchant/SalesChannel/${this.id}?id=${this.id}`;
        method = 'put';
        data = {
          id: this.id,
          name: this.name,
          royaltyTaxRate: (this.royalty) / 100,
          diversionTaxRate: (this.profit) / 100,
          startTime: this.startTime,
          endTime: this.endTime,
          code: this.code
        }
      }
      this.$http[method](url, data, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.updateLoading(false);
        // console.log(res.data);
        Swal.fire(
          '儲存成功',
          `${res.data.message}`,
          'success',
        );
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          } else if (err.response.status == 404) {
            Swal.fire(
              '',
              '找不到該筆通路',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          } else {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            )
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }

        this.$log.debug(err.response);
      });
    },
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Merchant/SalesChannel/${this.id}?id=${this.id}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.updateLoading(false);
        this.name = res.data.data.name;
        this.profit = ((res.data.data.diversionTaxRate) * 100).toFixed(1);
        this.royalty = ((res.data.data.royaltyTaxRate) * 100).toFixed(1);
        this.code = res.data.data.code;
        this.startTime = res.data.data.startTime;
        this.endTime = res.data.data.endTime;
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          } else {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/admin/home');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    if(this.$route.params.id) {
      this.mode = 'edit';
      this.id = this.$route.params.id;
      this.getData();
      // console.log('with param');
    } else {
      this.mode = 'new';
      // console.log('without param');
    }
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }

  .rotate {
    transform: rotate(180deg);
  }

  ::v-deep .mx-datepicker {
    width: 100%;
  }
</style>
